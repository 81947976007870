import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import { 
    GENERATE_ORDERS_REQUEST,
    GENERATE_ORDERS_SUCCESS,
    GENERATE_ORDERS_ERROR 
  } from "./ActionType";
  
const DEMO_MODE = false;

const DEMO_ORDERS = [
  { orderNumber: "DEMO-1001", paypalTransactionId: "DEMO-PP-123456789" },
  { orderNumber: "DEMO-1002", paypalTransactionId: "DEMO-PP-987654321" },
  { orderNumber: "DEMO-1003", paypalTransactionId: "DEMO-PP-456789123" }
];


export const generateOrders = (shopId, orderData) => {
  return async (dispatch) => {
    dispatch({ type: GENERATE_ORDERS_REQUEST });

    console.log(`orderData: ${JSON.stringify(orderData)}`)

    try {
      let data;
      
      if (DEMO_MODE) {
        // In demo mode, return fake data after a small delay to simulate network request
        await new Promise(resolve => setTimeout(resolve, 1000));
        data = DEMO_ORDERS;
      } else {
        const generateUrl = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.orderGenerator()}`;
        const response = await fetch(generateUrl, {
          method: "POST",
          credentials: "include",
          headers: {
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
            "Content-Type": "application/json",
            Origin: `${process.env.REACT_APP_URL}`,
          },
          body: JSON.stringify(orderData),
        });
        data = await response.json();
        const windowFeatures = "width=800,height=600,resizable=no,scrollbars=no,status=no";
        window.open(data.payPalPaymentApprovalUrl, '_blank', windowFeatures);
      }
      dispatch({
        type: GENERATE_ORDERS_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: GENERATE_ORDERS_ERROR,
        payload: error.message,
      });
      throw error;
    }
  };
};
