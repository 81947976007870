import React from "react";
import "./style.css";
import {
  IndexTable,
  LegacyCard,
  Text,
  EmptySearchResult,
} from "@shopify/polaris";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "./OrderStatus";


const Table = (props) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const emptyStateMarkup = (
    <EmptySearchResult
      title={t("No_orders_found")}
      description={t("filter_message")}
      withIllustration
    />
  );

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  const resourceName = {
    singular: "orders",
    plural: "orders",
  };

  const rowMarkup = props?.customers.map(
    (
      {
        carrier,
        name,
        storeOrderId,
        transactionId,
        fulfillmentId,
        paypalCarrier,
        orderStatus,
        processedAt,
        createdAt,
        trackingNumber,
        statusMessage,
        statusDescription,
        gateway,
        timeToSync,
        processingMethod,
        merchantAccountId,
        trackingUrl
      },
      index,
    ) => (
      <IndexTable.Row id={fulfillmentId} key={fulfillmentId} position={name}>
        <IndexTable.Cell>
          <p className="">
            <a
              style={{ color: "#315db0" }}
              href={`/viewStore/${id}/shopify/trouble-shooting?order-checker=${storeOrderId}`}
              target="_blank"
            >
              {storeOrderId}
            </a>
          </p>
          <p className="table-data-secondary">{name}</p>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <p className="">
            <a
              style={{ color: "#315db0" }}
              href={`https://www.paypal.com/activity/payment/${transactionId}`}
              target="_blank"
            >
              {transactionId}
            </a>
          </p>
          <Text>
            <p className="table-data-secondary">{gateway}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <p className="table-data">{trackingNumber !== 'DIGITAL_PRODUCT' &&
            (<a
              style={{ color: "#315db0" }}
              href={trackingUrl}
              target="_blank"
            >
              {trackingNumber}
            </a>)
          }</p>
          <Text variant="bodyMd" as="span" alignment="" numeric>
            {paypalCarrier !== "OTHER" && paypalCarrier !== carrier ? (
              <p className="table-data-secondary">
                {carrier} -> {paypalCarrier}
              </p>
            ) : (
              <p className="table-data-secondary">{carrier}</p>
            )}
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <OrderStatus
            orderStatus={orderStatus}
            statusMessage={statusMessage}
            statusDescription={statusDescription}
            processingMethod={processingMethod}
            merchantAccountId={merchantAccountId}
          />
        </IndexTable.Cell>
        <IndexTable.Cell>
          {processedAt === null ? "" : formatDateTime(processedAt)}
        </IndexTable.Cell>
        <IndexTable.Cell>{formatDateTime(createdAt)}</IndexTable.Cell>
        <IndexTable.Cell>
          {timeToSync === null ? "" : timeToSync}
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const details = (
    <p key="details" className="table_heading">
      {t("Details")}
    </p>
  );
  const tranaction = (
    <p key="tranaction" className="table_heading">
      {t("Transaction")}
    </p>
  );
  const courier = (
    <p key="courier" className="table_heading">
      {t("Courier")}
    </p>
  );
  const status = (
    <p key="status" className="table_heading">
      {t("Status")}
    </p>
  );
  const DateTime = (
    <p key="DateTime" className="table_heading">
      {t("DateAndtime")}
    </p>
  );
  const FullFilledAt = <div className="table_heading">{t("FullfilledAt")}</div>;
  const timeToSync = <div className="table_heading">{t("SyncTime")}</div>;

  return (
    <>
      <div style={{ marginTop: "20px", padding: "" }}>
        <LegacyCard title="Orders" sectioned>
          <LegacyCard.Section>
            <IndexTable
              resourceName={resourceName || 0}
              itemCount={props.customers.length}
              emptyState={emptyStateMarkup}
              headings={[
                { id: "details", title: details },
                { id: "tranaction", title: tranaction },
                { id: "courier", title: courier },
                { id: "status", title: status },
                { id: "DateTime", title: DateTime },
                { id: "FullFilledAt", title: FullFilledAt },
                { id: "timeToSync", title: timeToSync },
              ]}
              selectable={false}
            >
              {rowMarkup}
            </IndexTable>
          </LegacyCard.Section>
        </LegacyCard>
      </div>
    </>
  );
};

export default Table;
