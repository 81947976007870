import { React, useEffect, useState } from "react";
import { FulfillmentStatus } from "./orderGenerator/FulfillmentStatus";

import {
  LegacyCard,
  Page,
  Spinner,
  Banner,
  VerticalStack,
  Form,
  FormLayout,
  Select,
  Button,
  IndexTable,
  Text,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { generateOrders } from "../../../redux/store/orderGenerator/Action";
import { paypalSettingApi } from "../../../redux/troubleshooting/paypalSetting/Action";
import { useTranslation } from "react-i18next";
import { ORDER_QUANTITY_OPTIONS } from "./orderGenerator/utils";

const OrderGenerator = (props) => {
  const { id, storeType } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { generatedOrders, generating, generationError } = useSelector((state) => state.orderGenerator);
  const { isRequest, isSuccess, isError, data } = useSelector((store) => store.requriedPaypalSettingData);

  useEffect(() => {
    dispatch(
      paypalSettingApi({
        storeId: id,
        page: 0,
      })
    );
  }, [dispatch, id]);

  const [selectedAccount, setSelectedAccount] = useState("");
  const [fulfillmentStatus, setFulfillmentStatus] = useState(FulfillmentStatus.FULFILLED);
  const [payPalAccountOptions, setPayPalAccountOptions] = useState([{ label: "None", value: "" }]);

  useEffect(() => {
    let options = [{ label: "None", value: "" }]
    // Set initial account when data loads
    if (data?.content?.[0]) {
      setSelectedAccount(data?.content?.[0].merchantAccountId);
      let options = data?.content?.map(({ merchantAccountId }) => ({
        label: merchantAccountId,
        value: merchantAccountId,
      }));
      options.push({ label: "None", value: "" })
      setPayPalAccountOptions(options);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(
        generateOrders(id, {
          shopId: id,
          merchantAccountId: selectedAccount,
          fulfillmentStatus,
        })
      );
    } catch (error) {
      console.error("Failed to generate orders:", error);
    }
  };

  return (
    <Page fullWidth>
      <div className="maindiv">
        <div className="abc" style={{ marginBottom: "20px" }}>
          <LegacyCard sectioned>
            {isError ? (
              <Banner title={t("Error loading data")} status="critical">
                <p>{t("An error occurred while loading the data. Please try again later.")}</p>
              </Banner>
            ) : isRequest ? (
              <div style={{ display: "flex", justifyContent: "center", padding: "2rem" }}>
                <Spinner accessibilityLabel={t("Loading")} size="large" />
              </div>
            ) : (
              <VerticalStack gap="400">
                <div style={{ minWidth: "400px", margin: "0 auto" }}>
                  <Form onSubmit={handleSubmit}>
                    <FormLayout>

                      <Select
                        label={t("PayPal account")}
                        options={payPalAccountOptions}
                        value={selectedAccount}
                        onChange={setSelectedAccount}
                      />

                      <Select
                        label={t("Fulfillment status")}
                        options={[
                          { label: t("Fulfilled"), value: FulfillmentStatus.FULFILLED },
                          { label: t("Fulfilled (no carrier)"), value: FulfillmentStatus.FULFILLED_NO_CARRIER },
                          { label: t("Fulfilled (no tracking)"), value: FulfillmentStatus.FULFILLED_NO_TRACKING },
                          { label: t("Two fulfillments"), value: FulfillmentStatus.TWO_FULFILLMENTS },
                          {
                            label: t("Two fulfillments, one partial"),
                            value: FulfillmentStatus.TWO_FULFILLMENTS_PARTIAL,
                          },
                          { label: t("None"), value: FulfillmentStatus.NONE },
                        ]}
                        value={fulfillmentStatus}
                        onChange={setFulfillmentStatus}
                      />

                      <Button primary submit>
                        {t("Generate")}
                      </Button>
                    </FormLayout>
                  </Form>
                </div>
                <div id="results" style={{ width: "100%" }}>
                  {generating && (
                    <div style={{ marginTop: "20px" }}>
                      <LegacyCard sectioned>
                        <div style={{ display: "flex", justifyContent: "center", padding: "2rem" }}>
                          <Spinner accessibilityLabel={t("Generating orders")} size="large" />
                        </div>
                      </LegacyCard>
                    </div>
                  )}

                  {generationError && (
                    <div style={{ marginTop: "20px" }}>
                      <Banner title={t("Error generating orders")} status="critical">
                        <p>{generationError}</p>
                      </Banner>
                    </div>
                  )}

                  {!generating && !generationError && generatedOrders?.length > 0 && (
                    <div style={{ marginTop: "20px" }}>
                      <LegacyCard sectioned>
                        <IndexTable
                          resourceName={{ singular: "order", plural: "orders" }}
                          itemCount={generatedOrders.length}
                          headings={[{ title: t("Order Number") }, { title: t("PayPal Transaction ID") }]}
                          selectable={false}
                        >
                          {generatedOrders.map((order, index) => (
                            <IndexTable.Row id={order.orderNumber} key={index} position={index}>
                              <IndexTable.Cell>
                                <Text variant="bodyMd" fontWeight="bold">
                                  <a
                                    href={`https://admin.shopify.com/store/${id}/orders/${order.orderNumber}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#2C6ECB", textDecoration: "none" }}
                                  >
                                    {order.orderNumber}
                                  </a>
                                </Text>
                              </IndexTable.Cell>
                              <IndexTable.Cell>
                                <Text variant="bodyMd">
                                  <a
                                    href={`https://www.paypal.com/activity/payment/${order.paypalTransactionId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#2C6ECB", textDecoration: "none" }}
                                  >
                                    {order.paypalTransactionId}
                                  </a>
                                </Text>
                              </IndexTable.Cell>
                            </IndexTable.Row>
                          ))}
                        </IndexTable>
                      </LegacyCard>
                    </div>
                  )}
                </div>
              </VerticalStack>
            )}
          </LegacyCard>
        </div>
      </div>
    </Page>
  );
};

export default OrderGenerator;
