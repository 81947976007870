import {
  GENERATE_ORDERS_REQUEST,
  GENERATE_ORDERS_SUCCESS,
  GENERATE_ORDERS_ERROR,
} from "./ActionType";

const initialState = {
  loading: false,
  error: null,
  generatedOrders: [],
  generating: false,
  generationError: null,
};

const orderGeneratorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_ORDERS_REQUEST:
      return {
        ...state,
        generating: true,
        generationError: null,
      };
    case GENERATE_ORDERS_SUCCESS:
      return {
        ...state,
        generating: false,
        generatedOrders: action.payload,
      };
    case GENERATE_ORDERS_ERROR:
      return {
        ...state,
        generating: false,
        generationError: action.payload,
      };
    default:
      return state;
  }
};

export default orderGeneratorReducer;
